export const FirebaseConfig = {
	"projectId": "metrouo-894a5",
	"appId": "1:907062267226:web:2334c47f5abd80374bb763",
	"databaseURL": "https://metrouo-894a5-default-rtdb.firebaseio.com",
	"storageBucket": "metrouo-894a5.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDvgxn5fGByjRPx9RRK5sUC0tldJ1Ua114",
	"authDomain": "metrouo-894a5.firebaseapp.com",
	"messagingSenderId": "907062267226",
	"measurementId": "G-HS0MKPC2L3"
};